<template>
  <div>
    <!-- <div v-if="isLoading">
      <loader object="#fff" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="true" name="dots"></loader>
    </div> -->
    <div>
      <!-- MOBILE -->
      <div v-if="isMobileWidth === 1">
        <v-row class="ma-4 mt-0 pt-0">
          <v-col cols="12">
            <banner-home></banner-home>
          </v-col>
          <v-col cols="12" class="mt-3 mb-5">
            <category-home></category-home>
          </v-col>
        </v-row>
        <div class="spacerRow"></div>
        <!-- <v-row class="ma-4 mt-2 pt-0">
          <v-col cols="12">
            <premium-home></premium-home>
          </v-col>
        </v-row> -->
        <div class="spacerRow"></div>
        <v-row class="ma-4 mt-2 pt-0">
          <v-col cols="12">
            <micro-home></micro-home>
          </v-col>
        </v-row>
        <div class="spacerRow"></div>
        <v-row class="ma-4 mt-2 pt-0">
          <v-col cols="12">
            <nano-home></nano-home>
          </v-col>
        </v-row>
        <div class="spacerRow"></div>
        <v-row class="ma-4 mt-3 pt-0">
          <v-col cols="12">
            <help-home></help-home>
          </v-col>
        </v-row>
        <div class="spacerRow"></div>
        <v-row class="ma-4 mt-3 pt-0">
          <v-col cols="12">
            <tiktok-home></tiktok-home>
          </v-col>
        </v-row>
        <div class="spacerRow"></div>
        <v-row class="ma-4 mt-3 pt-0">
          <v-col cols="12">
            <package-home></package-home>
          </v-col>
        </v-row>
        <div class="spacerRow"></div>
        <v-row class="ma-4 mt-3 pt-0">
          <v-col cols="12">
            <client-home></client-home>
          </v-col>
        </v-row>
        <div class="spacerRow"></div>
        <v-row class="ma-4 mt-3 pt-0 mb-10 pb-5">
          <v-col cols="12">
            <about-home></about-home>
          </v-col>
        </v-row>
        <!-- <v-row class="ma-4 mt-3 pt-0 mb-10 pb-5">
          <v-col cols="12">
            <video-home></video-home>
          </v-col>
        </v-row> -->
      </div>
      <div v-else>
        <v-row class="pa-0">
          <v-col cols="12">
            <banner-home></banner-home>
          </v-col>
          <v-col cols="12">
            <category-home></category-home>
          </v-col>
        </v-row>
        <!-- <v-row class="pa-0">
          <v-col cols="12">
            <premium-home></premium-home>
          </v-col>
        </v-row> -->
        <v-row class="pa-0">
          <v-col cols="12">
            <micro-home></micro-home>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col cols="12">
            <help-home></help-home>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col cols="12">
            <nano-home></nano-home>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col cols="12">
            <tiktok-home></tiktok-home>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col cols="12">
            <package-home></package-home>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col cols="12">
            <recomended-home></recomended-home>
          </v-col>
        </v-row>
        <v-row class="pa-0">
          <v-col cols="12">
            <client-home></client-home>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
const BannerHome = () => import('./components/Banner.vue')
// import BannerHome from './components/Banner.vue'
const CategoryHome = () => import('./components/Category.vue')
const NanoHome = () => import('./components/Nano.vue')
const MicroHome = () => import('./components/Micro.vue')
const HelpHome = () => import('./components/Help.vue')
const VideoHome = () => import('./components/Video.vue')
const PremiumHome = () => import('./components/Premium.vue')
const PackageHome = () => import('./components/Package.vue')
const ClientHome = () => import('./components/Client.vue')
const AboutHome = () => import('./components/About.vue')
const TiktokHome = () => import('./components/TiktokInflu.vue')
const RecomendedHome = () => import('./components/Recomended.vue')
import axios from 'axios'

export default {
  name: 'home',
  metaInfo: {
    title: 'Influencer and KOL Management Platform'
  },
  components: {
    BannerHome,
    CategoryHome,
    NanoHome,
    MicroHome,
    HelpHome,
    VideoHome,
    PremiumHome,
    PackageHome,
    ClientHome,
    AboutHome,
    TiktokHome,
    RecomendedHome
  },
  setup() {
    return {
      isLoading:false,
      isMobileWidth:1,
    }
  },
  mounted(){
    if(this.isMobileWidth === 0){
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
          this.isLoading = false
      } else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
    } else {
      this.isLoading = false
    }

    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    isMobile() {
      let size
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        size = 1
      } else {
        size = 0
      }

      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(size === 1){
        this.isMobileWidth =1
      }else {
        this.isMobileWidth = 0
      }
    },
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    dateFormat(value){
      return value.toString().slice(0, 10)
    }
  }
}
</script>
